.MainSection {
    padding-top: 60px;
}

.HeaderDiv {
    width: 85%;
    margin: auto;
    padding: 40px 0;
}

.HeaderDiv p {
    font-size: 18px;
    color: #333;
    text-align: left;
}

.CardInfoList {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 100%;
    justify-content: space-between;
    margin: auto;
    padding: 20px 0;

}

.EachContactItem {
    position: relative;
    background-color: #f2f2f2;
    width: 340px;
    height: 180px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    margin-top: 50px;
    margin-right: 20px;
}

.EachContactItemIcon {
    position: absolute;
    top: -45px;
    left: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.EachContactItemIcon i{
    font-size: 32px;
    transform: translate(-1px, 4px);
}

.EachContactItemText {
    padding: 20px 10px;
}

.SubTitle{
    font-size: 22px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
}

.EachContactItemText a {
    font-size: 22px;
    color: #007bff;
    text-decoration: none;
    transition: color 0.3s ease;
}

.WhatsappButton {
    background-color: #5096c4;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    font-size: 18px;
    transition: background-color 0.3s ease;
    border: none;
}

.WhatsappButton p {
    margin: 0;
    font-size: 18px;
    color: white;
    font-weight: 500;
}

.WhatsappButton:hover {
    background-color: #128C7E;
}

.ContactFormDiv {
    width: 85%;
    min-height: 60vh;
    margin: auto;
    display: flex;
    justify-content: center;

    margin-top: 40px;
    margin-bottom: 40px;
    background-color: #ececec;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.FormImageDiv {
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.FormContentDiv {
    width: 50%;
    padding: 40px;
}

.FormContentDiv p {
    font-size: 18px;
    color: #333;
    text-align: left;
    margin-top: 8px;
    margin-bottom: 12px;
}

.FormEachInput {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 16px;
}

/* .ContactDiv {
    padding: 20px 10px;
    background-color: #5096c4;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px 0;
    max-width: 600px;
    color: #ffffff;
}

.EachContactItem {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.EachContactItem i {
    margin-right: 15px;
    transform: translateY(3px);
    font-size: 22px;
    text-shadow: 0 0 4px #1897e6;
    transition: text-shadow 0.3s ease;
}

.EachContactItem:hover i {
    text-shadow: 0 0 8px #ffd501;
}

.Line {
    width: 100%;
    height: 1px;
    background-color: #ffffff;
    margin: 10px 0;
}

.SubLine {
    border: 0;
    border-bottom: 2px dashed #ccc;

} */