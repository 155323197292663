.Layout {
    display: grid;
    grid-template-columns: 240px 1fr;
    grid-row: 1fr;
}

.HideLayout {
    display: grid;
    grid-template-columns: 60px 1fr;
    grid-row: 1fr;
}

.Navbar {
    grid-column: 1 / 1;
    grid-row: 1 / 1;
    background-color: #f5f5f5;
    border-right: 1px solid #e0e0e0;
    padding: 20px;
}

.Outlet {
    grid-column: 2 / 2;
    grid-row: 1 / 1;
    max-height: 100vh;
    min-height: 100vh;
    overflow-y: auto;
    background-color: #f1f1f1;
}

.TestModeAlert {
    height: 3vh;
    position: relative;
    background-color: #fcb4b4;
    padding: 10px;
    border-bottom: 1px solid #e0e0e0;
    border-radius: 0 0 8px 8px;
}

.TestModeAlert p{
    position: absolute;
    top: 15px;
    left: 20px;
    font-weight: bold;
}

.TestModeAlert i{
    position: absolute;
    top: 15px;
    right: 20px;
    font-size: 22px;
}