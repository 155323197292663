.MainSection{
    padding-top: 80px;
    width: 70%;
    margin: auto;
}

.header {
    margin-top: 10px;
    margin-bottom: 10px;
}

.title{
    font-size: 30px;
    font-weight: 600;
}

.tabcontent p{
    font-size: 18px;
}

.tabcontent h1{
    margin-top: 30px;
    margin-bottom: 30px;
}

.tabcontent h3{
    margin-top: 20px;
    margin-bottom: 20px;
}

.tabcontent h2{
    margin-top: 20px;
    margin-bottom: 20px;
}

.tabcontent ul{
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 50px;
}
