.MainHomePage {
    height: 600px;
}

.BannerDiv {
    position: relative;
}

.BannerImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 650px;
    overflow: hidden;
}

.BannerImage img {
    width: 100%;
    filter: grayscale(30%) brightness(55%);
}

.BannerText {
    position: absolute;
    top: 240px;
    left: 120px;
    color: white;
}

.BannerText h1{
    color: white;
    font-size: 33px;
    margin-bottom: 12px;
    text-shadow: 1px 1px 3px rgb(135, 58, 186);
}

.BannerText p{
    color: white;
    font-size: 18px;
}

.BannerButtonDiv {
    position: absolute;
    top: 400px;
    left: 120px;
}

.BannerButton {
    background-color: #faa700;
    color: rgb(33, 7, 6);
    border: none;
    padding: 10px 20px;
    font-size: 20px;
    cursor: pointer;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(255, 255, 255, 0.2);
    font-weight: 600;
    transition: background-color 0.3s, transform 0.3s, color 0.3s;
}

.BannerButton:hover {
    background-color: #f8a700;
    color: #131313;
    transform: scale(1.05);
}

.contectpart1Div {
    width: 80%;
    height: 400px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contectpart1Text h1{
    font-size: 33px;
    text-shadow: 1px 1px 1px rgb(160, 160, 160);
}

.contectpart1Text p {
    font-size: 18px;
    margin-top: 10px;
    text-shadow: 1px 1px 1px rgb(160, 160, 160);
}

.EachPartADiv {
    background-color: rgb(237, 237, 237);
    padding: 20px 10px;
}

.EachPartBDiv {
    background-color: rgb(255, 255, 255);
    padding: 20px 10px;
}

.CardInfo {
    width: 80%;
    margin: auto;
    display: flex;
}

.CardImage {
    display: flex;
    justify-content: center;
    width: 30%;
    height: 300px;
}

.CardImage img{
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.CardText {
    display: flex;
    flex-direction: column;
    width: 70%;
    justify-content: center;
}

.CardText h1{
    color: #071d4f;
    font-size: 28px;
    margin-bottom: 10px;
}

.CardText p{
    color: #131313;
    font-size: 20px;
}

.SupportPlatformDiv {
    padding: 20px 10px;
    width: 80%;
    margin: auto;
}

.SupportPlatformImage {
    display: flex;
    width: 80%;
    margin: auto;
    justify-content: space-around;
}

.SupportPlatformImage img{
    width: 150px;
}

.SupportPlatformText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;
    margin: auto;
}

.SupportPlatformText h1{
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 30px;
    text-shadow: 1px 1px 1px rgb(160, 160, 160);
}