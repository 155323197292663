.MainSection {
    padding-top: 60px;
}

.AboutUsDiv {
    width: 85%;
    margin: auto;
    padding: 40px 0;
    display: flex;
}

.LogoDiv img{
    width: 285px;
    height: 240px;
}

.AboutUsContent {
    padding: 20px;
}

.AboutUsContent h1 {
    font-size: 30px;
    color: #333;
    text-align: left;
    margin-bottom: 12px;
}

.AboutUsContent p {
    font-size: 22px;
    color: #373737;
    text-align: left;
    margin-top: 12px;
}

.OurMissionDiv {
    position: relative;
    width: 85%;
    margin: auto; 
    margin-top: 50px;
    margin-bottom: 30px;
    padding: 40px 0;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.8);
    border: 3px solid #e0e0e0;
}

.MissionImage {
    width: 130px;
    height: 130px;
    position: absolute;
    top: -65px;
    left: 60px;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.8);
    background-color: #ffffff;
}

.MissionText {
    margin-top: 20px;
    padding: 40px 60px;
}

.MissionText p {
    font-size: 20px;
    margin-top: 5px;
    color: #003776;
}