.MainSection {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 9998;
    display: flex;
    justify-content: center;
    align-items: center;  
    align-items: center;
}

.BackgroundDiv {
    border-radius: 24px;
    background-color: #ffffff;
    width: 90vw;
}

.HeaderDiv {
    display: flex;
    position: relative;
}

.HeaderDiv p {
    position: absolute;
    font-size: 24px;
    font-weight: bold;
    top: 24px;
    left: 30px;
}

.TopControlDiv {
    position: absolute;
    top: 24px;
    left: 200px;
}

.TopControlDiv button {
    border: none;
    padding: 5px 8px;
    color: #ffffff;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
    font-size: 16px;
    margin-right: 10px;
}

.SearchBar {
    position: absolute;
    top: 20px;
    right: 120px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 200px;
    height: 32px;
    background-color: #fff;
    border-radius: 8px;
    border: 2px solid #575757;
    padding: 0 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.SearchBar input[type="text"] {
    border: none;
    width: 100%;
    height: 26px;
}

.SearchBar input[type="text"]:focus {
    outline: none;
}

.SearchBar i {
    font-size: 22px !important;
    transform: translate(13px, -14px);
}

.shopingCart{
    position: absolute;
    top: 6px;
    right: 60px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.shopingCart i {
    font-size: 26px !important;
}


.HeaderDiv i {
    position: absolute;
    font-size: 36px;
    font-weight: bold;
    top: 20px;
    right: 20px;
}

.MainUi {
    display: flex;
    width: 92%;
    margin: auto;
    margin-top: 80px;
    margin-bottom: 20px;
    background-color: #e1e1e1;
    border-radius: 12px;
    padding: 20px;
}

.BasicInputDiv {
    width: 40%;

}

.EachInputDiv {
    margin-bottom: 8px;
}

.EachInputDiv label {
    margin: 0;
    margin-left: 2px;
    font-weight: 500;
}

.EachInputDiv select {
    width: 100%;
    height: 35px;
    border: none;
    border-radius: 5px;
    padding-left: 5px;
    background-color: #ffffff;
}

.ProductListDiv {
    position: relative;
    margin-left: 15px;
    width: 85%;
}

.AddProductControlDiv {
    position: absolute;
    right: 0px;
    top: -10px;
}

.AddProductControlDiv button {
    border: none;
    padding: 5px 8px;
    background-color: #67362A;
    color: #ffffff;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
    font-size: 16px;
}

.AddProductControlDiv button:hover {
    background-color: #47261d;
}

.ProductTableDiv {
    margin-top: 30px;
    background-color: #ffffff;
    max-height: 57vh;
    overflow-y: auto;
}

.ProductTableDiv table {
    font-family: 'Oswald', sans-serif;
    border-collapse: collapse;
}

.ProductTableDiv th {
    color: #000000;
    width: 25vw;
    height: 35px;
    text-align: left;
    padding-left: 5px;
}

.ProductTableDiv td {
    background-color: #ffffff;
    width: 25vw;
    height: 50px;
    text-align: left;
    padding-left: 10px;
}

.ProductTableDiv tr {
    border-bottom: 1px solid #dddddd;
}

.ProductTableDiv td:last-of-type {
    
}

.ProductTableDiv tr:nth-of-type(even) td {
    background-color: #f3f3f3;
}

.CreateTicketDiv {
    position: relative;
    width: 100%;
    height: 40px;
}

.CreateTickButton {
    position: absolute;
    right: 10px;
    bottom: -10px;
    margin: 20px;
    padding: 10px 20px;
    background-color: #faaf1a;
    color: #000000;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
    font-size: 16px;
    border: none;
}

.ProductTypeOptionDiv {
    max-width: 94%;
    padding: 5px;
    margin: auto;
    display: flex;
    flex-wrap: nowrap; /* Prevent wrapping */
    margin-top: 70px;
    overflow-x: auto; /* Hide overflowing horizontally */
    white-space: nowrap; /* Prevent internal wrapping */
}

.ProductTypeOptionDiv::-webkit-scrollbar {
    height: 5px;
}

.ProductTypeFieldset {
    flex: 0 0 auto; /* Prevent shrinking */
    padding: 5px 8px;
    border-radius: 5px;
    width: 100px;
    display: flex;
    align-items: center;
    background-color: #faaf1a;;
    margin-left: 10px;
}

.ProductTypeFieldset label{
    margin: 0;
    margin-left: 2px;
    font-weight: 500;
}

.SelectProductListDiv {
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 96%;
    height: 70vh;
    border-radius: 8px;
    border: 2px #000000 solid;
    overflow-y: auto;
}

.EachProductItemDiv {
    position: relative;
    width: 95%;
    margin: auto;
    height: 110px;
    padding: 10px;
    margin-top: 5px;
    border-radius: 8px;
}

.ProductImgDiv {
    position: absolute;
    width: 110px;
    height: 110px;
}

.ProductNameDiv {
    position: absolute;
    left: 130px;
    top: 10px;
    width: 60%;
    font-weight: bolder;
}

.ProductDescDiv {
    position: absolute;
    left: 130px;
    top: 40px;
    width: 60%;
    color: #575757;
}

.ProductStockStatusDiv {
    display: flex;
    position: absolute;
    left: 130px;
    bottom: 20px;
    width: 100px;
    font-weight: bolder;
}

.ProductStockStatusDiv i{
    transform: translateY(2px);
    margin-right: 5px;
}

.ProductFullStockDiv {
    padding: 5px 10px;
    width: 90px;
    text-align: center;
    background-color: #9FF086;
    border-radius: 8px;
    position: absolute;
    right: 180px;
    top: 10px;
    font-weight: bolder;
    cursor: pointer;
}

.ProductFullStockDiv:hover {
    background-color: #5cfe79;
}

.Product80StockDiv {
    padding: 5px 10px;
    width: 90px;
    text-align: center;
    background-color: #FBF39F;
    border-radius: 8px;
    position: absolute;
    right: 180px;
    top: 50px;
    font-weight: bolder;
    cursor: pointer;
}

.Product80StockDiv:hover {
    background-color: #fff048;
}

.Product50StockDiv {
    padding: 5px 10px;
    width: 90px;
    text-align: center;
    background-color: #FB9FAE;
    border-radius: 8px;
    position: absolute;
    right: 180px;
    top: 90px;
    font-weight: bolder;
    cursor: pointer;
}

.Product50StockDiv:hover {
    background-color: #ff4d5e;
}

.ProductOrderValueDiv {
    position: absolute;
    right: 10px;
    top: 50px;
    width: 160px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: #ffffff;
}

.ProductOrderAddDiv {
    position: absolute;
    right: 8px;
    top: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
}

.ProductOrderAddDiv i{
    font-size: 22px;
    color: #575757;
    cursor: pointer;
}

.ProductOrderMinusDiv {
    position: absolute;
    right: 130px;
    top: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
}

.ProductOrderMinusDiv i{
    font-size: 22px;
    color: #575757;
    cursor: pointer;
}

.ProductOrderValueDiv input[type="number"] {
    width: 80px;
    height: 30px;
    text-align: center;
    /* border: none; */
    border-radius: 8px;
    margin: 0 5px;
    -moz-appearance: textfield;
    appearance: textfield;
    -webkit-appearance: none;
}

.ButtonContrlDiv {
    display: flex;
    justify-content: end;
    margin-right: 20px;
    margin-bottom: 10px;
}

.ButtonContrlDiv button {
    border: none;
    padding: 6px 22px;
    background-color: #67362A;
    color: #ffffff;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 600;
    font-size: 16px;
    margin-right: 10px;
    font-size: 20px;
}

.ButtonContrlDiv button:hover {
    background-color: #47261d;
}

.CartEditProduct {
    padding: 3px 5px;
    background-color: #E8FFE5;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 5px;
    font-weight: 500;
    font-size: 16px;
}

.CartEditProduct:hover {
    background-color: #C7FFC7;
}

.CardDeleteProduct {
    padding: 3px 5px;
    background-color: #FFC7C7;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 5px;
    font-weight: 500;
    font-size: 16px;
}

.CardDeleteProduct:hover {
    background-color: #FFA5A5;
}

.DatePicker {
    background-color: rgb(255, 255, 255);
    border: 1px solid #000000;
    width: 100%;
    height: 42px;
    margin-right: 20px;
}