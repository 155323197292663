@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Outfit:wght@100..900&display=swap');

* {
    padding: 0;
    margin: 0;
    font-family: "Outfit", sans-serif;
}

.TopSection {
    margin: auto;
    margin-top: 1.8em;
    width: 95%;
    background-color: rgb(255, 255, 255);
    border-radius: 12px;
    position: relative;
    padding: 1em;
    min-height: 100vh;
    box-shadow: #000000 0px 0px 10px;
}

.AddUserBtnDiv {
    position: absolute;
    top: -20px;
    right: 10px;
}

.ControlBar {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
}

.TopDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
}

.Title {
    font-size: 30px;
    font-weight: 600;
}

.SeachUser {
    right: 1em;
    font-size: 22px;
    border-radius: 5px;
    text-align: left;
}

.ListUser{
    margin-top: 20px;
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
}

.UserTable {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

.UserTable td,
.UserTable th {
    border: 1px solid #ddd;
    padding: 8px;
}

.UserTable tr:nth-child(even) {
    background-color: #f2f2f2;
}

.UserTable tr:hover {
    background-color: #ddd;
}

.UserTable th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #faaf1a;
    color: #5e3737;
}

.EditUserBtn {
    text-align: center;
}

.EditUserBtn:hover {
    cursor: pointer;
}

.BottomBtn {
    background-color: #741715;
    color: white;
    border: none;
    padding: 8px 10px;
    border-radius: 5px;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    display: inline-flex;
    /* Ensure it's a flex container to center text and icon */
    align-items: center;
    margin-right: 8px;
    /* Vertically centers text and icon */
}

.BottomBtn i{
    transform: translateY(10%);
    margin-right: 10px;
}


@media screen and (max-width: 768px) {

    

}