.TopSection {
    display: flex;
    position: relative;
    padding: 20px;
    background-color: #f5f5f5;
    border-bottom: 1px solid #989898;
}

.TopSection p {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
}

.SearchBar {
    position: absolute;
    top: 15px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 200px;
    height: 32px;
    background-color: #fff;
    border-radius: 8px;
    border: 2px solid #575757;
    padding: 0 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

}

.SearchBar input[type="text"] {
    border: none;
    width: 100%;
    height: 26px;
}

.SearchBar input[type="text"]:focus {
    outline: none;
}