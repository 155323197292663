.MainSection {
    padding-top: 80px;
}

.SolutionDiv {
    background-color: #f9f9f9;
    width: 85%;
    margin: auto;
    padding: 20px 30px;
    border-radius: 8px;
    border: 2px solid #e0e0e0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

}

.SolutionPageTitle {
    width: 100%;
    display: flex;
    margin-bottom: 5px;
}

.SolutionPageContent {
    margin-top: 12px;
}